import React, { useState, useEffect } from 'react';
import qs from 'qs';
import BlogBrowserControls from './BlogBrowserControls';
import BlogBrowserHeader from './BlogBrowserHeader';
import BlogBrowserFilterList from './BlogBrowserFilterList';
import BlogBrowserCardsList from './BlogBrowserCardsList';
import { navigate, useLocation } from '@reach/router';

const BlogBrowserContext = React.createContext({});

const BlogBrowserEmptyFilterMessage = () => {
  return (
    <div className="blog-browser__message">
      <p>This combination of filters doesn't yield any results.</p>
    </div>
  );
};
const BlogBrowser = ({ resources }) => {
  // These are our filter groups.
  // 'type' or 'category' is a filterGroupSlug
  const initialFilterState = {
    contentType: [],
    categories: [],
    roles: [],
  };

  const location = useLocation();

  const [filters, setFilters] = useState(initialFilterState);

  // Bool for if there are _any_ filters being used.
  const hasActiveFilters =
    Object.keys(filters)
      .map((k, i) => [...filters[k]])
      .flat().length !== 0;

  // If we show up with a search string, parse it into filter state
  useEffect(() => {
    if (location.search) {
      const decoded = decodeState(location.search.replace('?', ''));
      setFilters({ ...filters, ...decoded });
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      const decoded = decodeState(location.search.replace('?', ''));
      setFilters({ ...filters, ...decoded });
    }
  }, [location.search]);

  // Whenever the filter state changes, encode it and put it on the URL
  useEffect(() => {
    const encoded = encodeState(getFiltersAsSlugs(filters));
    const url = `${location.pathname}${encoded ? '?' + encoded : ''}`;
    navigate(url, { replace: true });
  }, [filters]);

  // Reduce unique tags from resources
  const uniqueTags = resources
    .reduce((acc, cv) => {
      // Get all the tags from the three groups
      const cvFilters = [...cv.categories, ...cv.contentType, ...cv.roles];

      // See which if any of this CV's tags are unique.
      const cvUniqueTags = cvFilters.filter((cvTag) => {
        // look through acc and see if it exists.
        // We need it to return a TRUE to get into the unique list,

        // if it exists in the array, it'll return 1 when it's found
        // if it doesn't, it'll return a 0 because it's not there.
        // so we want to add any filter that has 0 other matches.
        return acc.filter((accTag) => accTag.slug === cvTag.slug).length === 0;
      });

      // unique tags will either be an empty array or have the unique tags
      return [...acc, ...cvUniqueTags];
    }, [])
    .map(({ title, slug }) => ({
      // trim everything, whitespace can cause an error.
      title: title.trim(),
      slug: slug.trim(),
    }));

  // State => query string params
  const encodeState = (state) => {
    return qs.stringify(getFiltersAsSlugs(filters), {
      indices: false,
      encode: false,
      arrayFormat: 'comma',
    });
  };

  // Query string params => state
  const decodeState = (queryString) => {
    return getFiltersFromSlugs(qs.parse(queryString));
  };

  // Add titles back after decoding
  const getFiltersFromSlugs = (myFilters) => {
    const entriesModified = Object.entries(myFilters).map(([key, value]) => {
      const tags = value.split(',').map((slug) => {
        const matchingTag = uniqueTags.filter((tag) => tag.slug === slug);
        if (matchingTag.length) return matchingTag[0];
        return null;
      });
      return [key, tags];
    });

    return Object.fromEntries(entriesModified);
  };

  // Remove titles for encoding
  const getFiltersAsSlugs = (myFilters) => {
    const entriesModified = Object.entries(myFilters).map(([key, value]) => {
      if (value.length === 0) return [];
      return [key, value.map((filterItem) => filterItem.slug)];
    });
    return Object.fromEntries(entriesModified);
  };

  // Clear all Filters
  const resetFilters = () => {
    navigate(location.pathname, { replace: true });
    setFilters(initialFilterState);
  };

  const sortByFeatured = (a, b) => {
    if (a.featuredContent && !b.featuredContent) return -1;
    if (!a.featuredContent && b.featuredContent) return 1;
    return 0;
  };

  const filterByType = (item) => {
    if (filters.contentType.length === 0) return true;
    return item.contentType.slug === filters.contentType[0].slug;
  };

  const filterByCategory = (item) => {
    if (filters.categories.length === 0) return true;
    const activeCategorySlugs = filters.categories.map((r) => r.slug).flat();
    return item.categories.reduce((acc, cv) => {
      if (activeCategorySlugs.includes(cv.slug)) return true;
      return acc;
    }, false);
  };

  const filterByRole = (item) => {
    if (filters.roles.length === 0) return true;
    const activeCategorySlugs = filters.roles.map((r) => r.slug).flat();
    return item.roles.reduce((acc, cv) => {
      if (activeCategorySlugs.includes(cv.slug)) return true;
      return acc;
    }, false);
  };

  const sortByPostDate = (a, b) => {
    const aDate = new Date(a.publishDate);
    const bDate = new Date(b.publishDate);

    return bDate - aDate;
  };

  const filteredResources = resources
    .filter(filterByType)
    .filter(filterByCategory)
    .filter(filterByRole)
    .sort(sortByPostDate)
    .sort(sortByFeatured);

  const context = {
    filteredResources: filteredResources,
    resources: resources,
    filters: filters,
    filterByType: filterByType,
    setFilters: setFilters,
    resetFilters: resetFilters,
    hasActiveFilters: hasActiveFilters,
  };

  return (
    <div className="blog-browser">
      <BlogBrowserContext.Provider value={{ ...context }}>
        <BlogBrowserHeader count={filteredResources.length} />
        {filteredResources.length !== 0 ? (
          <BlogBrowserCardsList cards={filteredResources} />
        ) : (
          <BlogBrowserEmptyFilterMessage />
        )}
      </BlogBrowserContext.Provider>
    </div>
  );
};

BlogBrowser.defaultProps = {
  resources: [],
};

export { BlogBrowser, BlogBrowserContext };
