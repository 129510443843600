import React from 'react';
import PropTypes from 'prop-types';
import { PillItem, PillsList } from '../PillsList';
import BackgroundImage from 'gatsby-background-image';
import { navigate } from 'gatsby';
import qs from 'qs';
import Link from '../Link';
import styled from 'styled-components';

const BlogCard = (props) => {
  const {
    contentType,
    roles,
    categories,
    featuredContent,
    showIsFeatured,
    shortTitle,
    slug,
    author,
    externalResource,
    externalResourceUrl,
    cardImage,
    variant,
  } = props;

  const StyledLink = styled.a`
    color: #ffffff;
    padding-bottom: 1px;
    border-bottom: 1px solid #2d2d2d;
    text-decoration: none;
  `;
  const cardUrl = externalResource ? externalResourceUrl : `/blog/${slug}`;

  const handlePillClick = () => {
    const filterState = { contentType: [contentType.slug] };
    const encoded = qs.stringify(filterState, {
      indices: false,
      encode: false,
      arrayFormat: 'comma',
    });
    const url = `/blog/?${encoded}`;
    navigate(url);
  };

  return (
    <div className={`blog-card blog-card--${variant}`}>
      <div className="blog-card__inner">
        <div className="blog-card__header">
          <Link href={cardUrl}>
            <BackgroundImage
              tag={'div'}
              fluid={cardImage?.fluid}
              className="blog-card__header-inner"
              style={{
                position: 'absolute',
              }}
            />
          </Link>
        </div>

        <div className="blog-card__body">
          <div className="blog-card__title">
            <h5>
              <Link href={cardUrl}>{shortTitle}</Link>
            </h5>
            <p className="blog-card__author">{author}</p>
          </div>
          <div className="blog-card__cta">
            <StyledLink href={cardUrl}>Read more</StyledLink>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  variant: PropTypes.oneOf(['horizontal', 'vertical']),
  showIsFeatured: PropTypes.bool,
};

BlogCard.defaultProps = {
  variant: 'vertical',
  showIsFeatured: true,
};

export default BlogCard;
