import React, { useContext } from 'react';
import { BlogBrowserContext } from './BlogBrowser';
import pluralize from 'pluralize';

const BlogBrowserHeader = ({ count }) => {
  const { filters, setFilters } = useContext(BlogBrowserContext);

  const typeFilterPresent = filters.contentType.length !== 0;

  return (
    <div className="blog-browser__header">
      <div className="blog-browser__header-inner">
        <div className="blog-browser__active-type">
          {typeFilterPresent
            ? pluralize(filters.contentType[0].title)
            : 'All Blog Posts'}
        </div>
        <div className="blog-browser__items-count">{`${count} results`}</div>
      </div>
    </div>
  );
};

export default BlogBrowserHeader;
