import React, { useRef, useEffect } from 'react';
import Image from 'gatsby-image';
import VideoPlaceholder from '../VideoPlaceholder';
import RichText from '../RichText';
import styled from 'styled-components';
import { inViewTriggerThresholds } from '../../animationGlobals';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const StyledEyebrow = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
  color: #ffb600;
`;

const StyledLink = styled.a`
  color: #ffffff;
  padding-bottom: 1px;
  border-bottom: 1px solid #ffffff;
`;

function BlogHero({
  title,
  ctaText,
  ctaLink,
  bodyText,
  asset,
  videoCoverImage,
}) {
  const placeholderPosterFluid = videoCoverImage?.fluid;
  const imageFluid = asset?.fluid;
  const videoSrc = asset?.file?.url;

  return (
    <section className="blog-hero">
      <div className="blog-hero__inner">
        <div className="blog-hero__content">
          <StyledEyebrow>Featured Blog Post</StyledEyebrow>
          <h2 className="blog-hero__title">{title}</h2>
          <div className="blog-hero__body">
            <RichText source={bodyText.json} />
          </div>
          <div className="blog-hero__link">
            <StyledLink href={ctaLink}>{ctaText}</StyledLink>
          </div>
        </div>
        <div className="blog-hero__media">
          <div className="blog-hero__media-inner">
            {imageFluid ? (
              <Image fluid={imageFluid} />
            ) : (
              <VideoPlaceholder
                posterFluid={placeholderPosterFluid}
                src={videoSrc}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

BlogHero.defaultProps = {
  title: 'Lorem ipsum dolor sit amet',
  bodyText:
    'Duis ligula magna, egestas ac ipsum sit amet, mattis sodales nulla. Duis iaculis consectetur iaculis. Mauris ultricies et nisi vitae tempor.',
  videoCoverImage: {
    fluid: {
      src: '',
    },
  },
  asset: {
    file: {
      url: '',
    },
  },
};

export default BlogHero;
