import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import Carat from '../../../assets/svg/carat.svg';
import Checkmark from '../../../assets/svg/dropdown-checkmark.svg';

const reduceUnique = (acc, cv) => {
  // see how many there are any that have the same slug already
  const existingCount = acc.filter((o) => o.slug === cv.slug).length;
  if (existingCount === 0) {
    // if there's none, add a count and add it to the acc
    return [...acc, { ...cv, count: 1 }];
  } else {
    // if there's one already
    // find it from the acc
    const extantOption = acc.filter((o) => cv.slug === o.slug)[0];
    // slice it out, update it, put it back in
    acc.splice(acc.indexOf(extantOption), 1, {
      ...extantOption,
      count: extantOption.count + 1,
    });
    return acc;
  }
  return acc;
};

const sortOptionsByTitle = (a, b) => {
  var titleA = a.title.toUpperCase();
  var titleB = b.title.toUpperCase();
  if (titleA < titleB) {
    return -1;
  }
  if (titleA > titleB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const Dropdown = ({
  placeholder,
  options,
  showCount,
  allowMultiple,
  showAllCount,
  pluralizeOptions,
  onChange,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const resetOption = {
    title: 'All',
    slug: '',
    count: showAllCount ? options.length : '',
  };

  // this is still want we want to use, but instead of the total elements, it should be the elements available given the current filters.
  const uniqueOptions = [
    resetOption,
    ...options.reduce(reduceUnique, []).sort(sortOptionsByTitle),
  ];

  const onSelectOption = (option, isActive) => {
    if (!allowMultiple) {
      // if it's a one-item select, close it after it's selected
      setIsOpen(false);
    }
    // calculate new state (toggle, or add?)
    // reduce it with the last value
    // send computed state to onChange

    let newValue = [];

    if (option.slug == resetOption.slug) {
      newValue = [];
      // close the dropdown if they pick the reset
      setIsOpen(false);
    } else if (isActive) {
      // filter out items with the slug of the clicked item
      newValue = value.filter((o) => o.slug !== option.slug);
    } else {
      if (allowMultiple) {
        // if multiples are allowed, add this to the value
        newValue = [...value, option];
      } else {
        // if multiples are not allowed, replace the value with this
        newValue = [option];
      }
    }

    onChange(newValue);
  };

  // If no items are selected, use the placeholder as the title.
  // If there's one item, show it. Show it pluralized if necessary.
  // If there's more than one item, show the count.

  const dropdownTitle =
    value.length === 0
      ? placeholder
      : value.length === 1
      ? pluralizeOptions
        ? pluralize(value[0].title)
        : value[0].title
      : // : value.map((v) => v.title).join(', ');
        `${value.length} selected`;

  return (
    <div
      className={`dropdown ${isOpen ? 'dropdown--open' : 'dropdown--closed'} ${
        allowMultiple ? 'dropdown--multiple-choice' : 'dropdown--single-choice'
      }`}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="dropdown__top" onClick={() => setIsOpen(!isOpen)}>
        <span>{dropdownTitle}</span>
        <Carat />
      </div>

      <div className="dropdown__menu">
        <div className="dropdown__options">
          {uniqueOptions.map((option, index) => {
            const { title, slug, count } = option;

            let isActive =
              value.filter((v) => option.slug === v.slug).length !== 0;

            // make "All" option true if there's no value
            if (slug === resetOption.slug && value.length === 0)
              isActive = true;

            const optionIsNotReset = option.slug !== resetOption.slug;
            return (
              <div
                className={`dropdown__option ${
                  isActive
                    ? 'dropdown__option--active'
                    : 'dropdown__option--inactive'
                }`}
                key={index}
                onClick={() =>
                  onSelectOption({ title: title, slug: slug }, isActive)
                }
              >
                <div className="dropdown__option-content">
                  {allowMultiple && (
                    <div className="dropdown__option-checkbox">
                      {isActive && <Checkmark />}
                    </div>
                  )}
                  <div className="dropdown__option-title">
                    {pluralizeOptions && optionIsNotReset
                      ? pluralize(title)
                      : title}
                  </div>
                  {showCount && (
                    <div className="dropdown__option-count">{count}</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  showCount: PropTypes.bool,
  showAllCount: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  options: PropTypes.array,
  pluralizeOptions: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

Dropdown.defaultProps = {
  showCount: false,
  showAllCount: false,
  allowMultiple: false,
  options: [],
  pluralizeOptions: false,
  value: [],
  onChange: () => null,
};

export default Dropdown;
